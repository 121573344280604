<template>
    <h2 class="text-lg pb-4 text-azul font-bold">Cargar archivos RIPS</h2>

    <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-4">
      <div class="lg:col-span-4">
          <div class="box grid gap-4 gap-y-6 p-4 text-sm grid-cols-1 md:grid-cols-6">

            <div class="md:col-span-2" >
              <label>Cliente:</label>
              <InputText
                id="clienteId"
                class="h-8 border mt-1 rounded w-full p-inputtext-xs"
                v-model="nombreCliente"
                disabled
              />
            </div>
            <div class="md:col-span-2">
            <label>Fecha:</label>
              <InputText
                id="dueDate"
                type="month"
                placeholder="Seleccione un mes.."
                class="h-8 border mt-1 rounded w-full p-inputtext-xs"
                v-model="model.radicadoFecha"
              />
              <MessageError :text="errors.radicadoFecha" />
            </div>
            <div class="md:col-span-6">
                <label class="text-sm">Adjuntar documentos</label>
                <div class="md:col-span-6 mt-6">
                    <button class="w-full">
                    <FilePond multiple ref="pondInstance" acceptedFileTypes="text/plain" @captureFile="onCaptureFile" :multipleFiles="true"/>
                    </button>
                </div>
            </div>
            <div class="md:col-span-6 div-btn-cargar">
              <button
                type="button"
                class="bg-blue-600 text-white px-3 py-2 ml-3 rounded-md btn-cargar"
                @click="onSubmit"
              >
                Cargar
              </button>
            </div>

            <div class="text-gray-400 mb-4 md:col-span-6">
              <p>Tamaño max soportado xxxx MB</p>
              <p>Extensión de archivos permitida .txt</p>
            </div>

          </div>
        <ProgressSpinner v-show="isLoading" />
      </div>

    </div>
</template>
<script>

import FilePond from '../../../juridica/procesosJuridicos/views/uploadFilePoundNew'
import { useForm, useField } from 'vee-validate'
import { object, string } from 'yup'
import { ref, computed } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useStore } from 'vuex'
import Swal from 'sweetalert2'
import InformesRipsService from './services'

export default {
name: 'cargaRips',
  components: {
    FilePond
  },
  setup () {
    const store = useStore()
    const pondInstance = ref()
    const toast = useToast()
    const _informesRipsService = new InformesRipsService()
    const isLoading = ref(false)
    const validateArray = ref([])
    const arrayAux = ref([])
    const nombreCliente = ref('UT-PHARMEDIS')
    const arrayFiles = ([])
    const equalsNameFile = (false)
    const user = computed(() => store.getters['auth/getPeople'])
    const validationSchema = object({ clienteId: string().typeError('El campo es requerido'), radicadoFecha: string().typeError('El campo es requerido') })
    const { handleSubmit, handleReset, values: model, errors } = useForm({ validationSchema })
    const _formData = new FormData()
    useField('clienteId', user.value.id, { initialValue: user.value.id })
    useField('radicadoFecha', null, { initialValue: null })

    const onCaptureFile = (file) => {
      validateArray.value.push(file)
      const nameFilesConsecutivo = []
      const nameFiles = []
      const ripsRequired = ['CT', 'US', 'AF', 'AC']
      for (const key in validateArray.value) {
        if (validateArray.value[key].file.type === 'text/plain') {
          nameFilesConsecutivo.push((validateArray.value[key].filename).slice(2, -4))
          nameFiles.push((validateArray.value[key].filename))
          if (validateArray.value[key].filename.startsWith('CT')) {
            arrayFiles.push('CT')
          }
          if (validateArray.value[key].filename.startsWith('US')) {
            arrayFiles.push('US')
          }
          if (validateArray.value[key].filename.startsWith('AF')) {
            arrayFiles.push('AF')
          }
        } else {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Los archivos a cargar deben tener extensión .txt', life: 3000 })
          isLoading.value = false
          pondInstance.value.onRemoveSupport()
          validateArray.value = []
        }
        _formData.append('archivo', validateArray.value[key].file)
      }
      const isSameNameFiles = nameFiles.some((e, i, arr) => arr.indexOf(e) !== i)
      if (isSameNameFiles) {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Se esta cargando mas de un archivo con el mismo nombre, por favor validar', life: 3000 })
        isLoading.value = false
        pondInstance.value.onRemoveSupport()
        return true
      }
      var containsAll = false
      for (let i = 0; i < validateArray.value.length; i++) {
        var countFilename = validateArray.value[i].filename.length - 2
        var empiezaCon = (validateArray.value[i].filename).slice(0, -countFilename)
        const bandera = (ripsRequired.includes(empiezaCon))
        if (!bandera) {
          containsAll = false
        } else {
          containsAll = true
        }
      }
      if (!containsAll) {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Los archivos CT, US y AF son obligatorios', life: 3000 })
        isLoading.value = false
        return true
      }
      const equalsNameFile = nameFilesConsecutivo.every((val, i, arr) => val === arr[i])
        if (!equalsNameFile) {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Los archivos a cargar deben tener el mismo consecutivo, por favor revisar', life: 3000 })
          isLoading.value = false
          pondInstance.value.onRemoveSupport()
          validateArray.value = []
        }
    }
    const onSubmit = handleSubmit(() => {
      if (validateArray.value.length > 0) {
        _formData.append('createdby', user.value.id)
        _formData.append('radicadoFecha', model.radicadoFecha + '-01')
        _informesRipsService.cargar(_formData).then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'Archivos cargados éxitosamente',
            showConfirmButton: true
          })
          validateArray.value = []
          isLoading.value = false
          setTimeout(() => {
            pondInstance.value.onRemoveSupport()
            _formData.delete('archivo')
            _formData.delete('radicadoFecha')
            _formData.delete('createdby')
            handleReset()
          }, 1000)
        })
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se han cargado achivos',
          showConfirmButton: true
        })
      }
    })
    return {
      handleSubmit,
      model,
      errors,
      isLoading,
      pondInstance,
      onCaptureFile,
      arrayAux,
      _informesRipsService,
      equalsNameFile,
      user,
      onSubmit,
      nombreCliente
    }
  }
}
</script>
<style lang="scss">
.filepond--root {
  min-height: 10rem;
}
.filepond--panel-root {
  border: 2px dashed #ccc;
}
.filepond--root .filepond--drop-label {
  min-height: 10rem;
}
.div-btn-cargar {
  display: flex;
  justify-content: center;
}
.btn-cargar {
  width: 150px;
}
</style>
